<template>
  <scrollable-container id="printable" :class="$style.scrollableContainer">
    <container :class="$style.scrollableContent">
      <div v-if="isTableGroupedByDates">
        <div v-for="date in tableDataGroupedByDates" :key="date.date">
          <div :class="$style.date">
            {{ formatDate(date.date) }}
          </div>
          <printer-report-table :departments="date.groups" :title="employee" />
        </div>
      </div>
      <div v-else>
        <printer-report-table :departments="tableDataGroupedByDepartments" :title="employee" />
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import { formatDate } from '@/helpers/format-date';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import PrinterReportTable from '@/components/common/table/printer-report-table.vue';
import { getEmployeeReport } from '@/api/methods/reports/printers/reports/index';

export default {
  components: {
    ScrollableContainer,
    Container,
    PrinterReportTable,
  },
  data: () => ({
    name: 'Отчет по сотрудникам',
    employee: { title: 'Отдел', element: 'employees' },
    tableDataGroupedByDates: [],
    tableDataGroupedByDepartments: [],
    columns: [
      { name: 'Сотрудник', sort: 0, field: 'name' },
      { name: 'Количество страниц', sort: 1, field: 'pages' },
      { name: 'Стоимость', sort: 0, field: 'cost' },
    ],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    isTableGroupedByDates() {
      return this.$store.getters['filters/isTableGroupedByDates'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.fetch();
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.tableDataGroupedByDates = [];
      this.tableDataGroupedByDepartments = [];
      if (this.dateRange.dateFrom && this.dateRange.dateTo) {
        this.promise = getEmployeeReport(
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ groupedByDates, groupedByGroups }) => {
          this.tableDataGroupedByDates = groupedByDates;
          this.tableDataGroupedByDepartments = groupedByGroups;

          const exportArray = [];
          groupedByGroups.forEach((dep) => {
            dep.employees.forEach((one) => {
              const resObj = { Отдел: dep.name };
              for (let j = 0; j < this.columns.length; j++) {
                resObj[this.columns[j].name] = one[this.columns[j].field];
              }
              exportArray.push(resObj);
            });
          });

          this.$store.commit('exportData/setEmployeeReport', { name: this.name, data: exportArray });

          return { groupedByDates, groupedByGroups };
        });
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style lang="scss" module>
.date {
  background-color: $light-gray;
  letter-spacing: -0.24px;
  width: 90px;
  height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  display: flex;
  align-items: center;
  color: #4b4b4b;
  margin-top: 15px;
  margin-bottom: 15px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
}

.scrollableContainer {
  padding-bottom: 20px;
}
</style>
